<!--
 * @Author: Scarlett Xiao
 * @Date: 2021-10-19 16:27:54
 * @LastEditors: Scarlett Xiao
 * @LastEditTime: 2021-10-20 10:41:43
-->
<template>
  <div class="ptz">
    <div class="outer-ring">
      <!-- 上 -->
      <a
        type="caret-up"
        class="caret-up el-icon-caret-top"
        id="0"
        @mousedown="ptzController(0, 'S')"
        @mouseup="ptzController(0, 'F')"
      />
      <!-- 下 -->
      <a
        type="caret-down"
        class="caret-down el-icon-caret-bottom"
        id="1"
        @mousedown="ptzController(1, 'S')"
        @mouseup="ptzController(1, 'F')"
      />
      <!-- 左 -->
      <a
        type="caret-left"
        class="caret-left el-icon-caret-left"
        id="2"
        @mousedown="ptzController(2, 'S')"
        @mouseup="ptzController(2, 'F')"
      />
      <!-- 右 -->
      <a
        type="caret-right"
        class="caret-right el-icon-caret-right"
        id="3"
        @mousedown="ptzController(3, 'S')"
        @mouseup="ptzController(3, 'F')"
      />
      <div class="inner-ring">
        <!-- 放大 -->
        <a
          type="plus"
          class="plus el-icon-plus"
          id="8"
          @mousedown="ptzController(8, 'S')"
          @mouseup="ptzController(8, 'F')"
        />
        <div class="line"></div>
        <!-- 缩小 -->
        <a
          type="minus"
          class="minus el-icon-minus"
          id="9"
          @mousedown="ptzController(9, 'S')"
          @mouseup="ptzController(9, 'F')"
        />
      </div>
      <!-- 左上 -->
      <a
        type="caret-left"
        class="ob-caret-left el-icon-caret-left"
        id="4"
        @mousedown="ptzController(4, 'S')"
        @mouseup="ptzController(4, 'F')"
      />
      <!-- 右上 -->
      <a
        type="caret-up"
        class="ob-caret-up el-icon-caret-top"
        id="6"
        @mousedown="ptzController(6, 'S')"
        @mouseup="ptzController(6, 'F')"
      />
      <!-- 右下 -->
      <a
        type="caret-right"
        class="ob-caret-right el-icon-caret-right"
        id="7"
        @mousedown="ptzController(7, 'S')"
        @mouseup="ptzController(7, 'F')"
      />
      <!-- 左下 -->
      <a
        type="caret-down"
        class="ob-caret-down el-icon-caret-bottom"
        id="5"
        @mousedown="ptzController(5, 'S')"
        @mouseup="ptzController(5, 'F')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PTZ',
  components: {},
  props: {
    token: String, // 视频token
    videoCode: String, // 设备编码
  },
  data() {
    return {
      deviceSerial: null, // 视频展示
    }
  },
  methods: {
    // 云台控制
    ptzController(/*方向0~11*/ conno, /*S:开始,F:结束*/ label) {
      const params = `accessToken=${this.token}&deviceSerial=${this.videoCode}&channelNo=1&direction=${conno}&speed=1`
      if (label === 'S') {
        this.$axios({
          method: 'post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          url: '/api/lapp/device/ptz/start',
          data: params,
        }).then((data) => {
          console.log('长按开始', data)
          if (data.code != '200') {
            this.$message.warning(data.msg)
          }
        })
      } else {
        this.$axios({
          method: 'post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          url: '/api/lapp/device/ptz/stop',
          data: params,
        }).then((data) => {
          if (data.code != '200') {
            this.$message.warning(data.msg)
          }
        })
      }
    },
  },
  created() {
    this.show = true
  },
  mounted() {
    // console.group('mounted 组件挂载完毕状态===============》')
    // if (this.videoUrl) {
    //   setTimeout(() => {
    //     this.player = new EZUIKit.EZUIPlayer('myPlayer')
    //     setTimeout(() => {
    //       this.player.play()
    //     }, 30)
    //     this.player.on('start', function (e) {
    //       console.log('加载成功')
    //     })
    //     this.player.on('error', function () {
    //       console.log('视频连接错误')
    //     })
    //   }, 0)
    // }
    // 引用 element-resize-detector，用户监听元素宽度变化
    // const elementResizeDetectorMaker = require('element-resize-detector')
    // // 创建实例
    // const erd = elementResizeDetectorMaker()
    // erd.listenTo(document.getElementById('my-video'), (element) => {
    //   this.show = false
    //   setTimeout(() => {
    //     this.show = true
    //   }, 5)
    // })
  },

  beforeDestroy() {
    // 停止播放
    // this.player && this.player.destroy() && this.player.stop()
  },
}
</script>
<style scoped lang="less">
.ptz {
  [class*=' el-icon-'],
  [class^='el-icon-'] {
    font-size: 22px;
    transition: all 0.2s;
    color: #666;

    &:hover {
      text-decoration: none;
      color: #f5f7fa;
      cursor: pointer;
    }
  }

  .outer-ring {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);

    i {
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: #00b176;
      }
    }

    .caret-up {
      position: absolute;
      top: 6px;
      left: 75px;
      margin-left: -10px;
    }

    .caret-down {
      position: absolute;
      bottom: 6px;
      left: 75px;
      margin-left: -10px;
    }

    .caret-left {
      position: absolute;
      left: 6px;
      top: 75px;
      margin-top: -10px;
    }

    .caret-right {
      position: absolute;
      right: 6px;
      top: 75px;
      margin-top: -10px;
    }

    .ob-caret-left {
      transform: rotate(45deg);
      position: absolute;
      top: 24px;
      left: 24px;
    }

    .ob-caret-up {
      transform: rotate(45deg);
      position: absolute;
      top: 24px;
      right: 24px;
    }

    .ob-caret-right {
      transform: rotate(45deg);
      position: absolute;
      bottom: 24px;
      right: 24px;
    }

    .ob-caret-down {
      transform: rotate(45deg);
      position: absolute;
      bottom: 24px;
      left: 24px;
    }

    .inner-ring {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 1px solid #ddd;
      background-color: rgba(0, 0, 0, 0.2);

      .plus {
        position: absolute;
        top: 10px;
        left: 40px;
        margin-left: -10px;
        font-size: 24px;
      }

      .line {
        height: 1px;
        width: 100%;
        background-color: #ddd;
        position: absolute;
        top: 39px;
        left: 0;
      }

      .minus {
        position: absolute;
        bottom: 10px;
        left: 40px;
        margin-left: -10px;
        font-size: 24px;
      }
    }
  }
}
</style>
